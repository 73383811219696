<template>
  <!-- card -->
  <el-card class="box-card">
    <!-- card header -->
    <div slot="header" class="clearfix">
      <span>{{ title }}</span>
      <el-button
        v-if="url"
        class="tz-r tz-create-btn"
        type="primary"
        round
        @click="jump(url)"
        >{{ btnTitle }}</el-button
      >
    </div>
    <!-- card header -->

    <!-- card content -->
    <slot></slot>
    <!-- card content -->
  </el-card>
  <!-- card -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String
    },
    btnTitle: {
      type: String
    }
  }
}
</script>

<style></style>
