import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Index.vue'
import Workplace from '@/views/workplace/Index.vue'
import Login from '@/views/login/Index.vue'

import NProgress from 'nprogress' // 导入 nprogress
import 'nprogress/nprogress.css'
import { login } from '@/utils/brequest'
import { SHA256 } from '@/utils/util'

NProgress.configure({
  easing: 'ease',
  showSpinner: false
})
// 显示右上角螺旋加载提示
// import Welcome from '@/views/Welcome.vue'
// import Detection from '@/views/detection/Index.vue'
// import Inspect from '@/views/inspect/Index.vue'
// import Workload from '@/views/workload/index.vue'
// import Custvolume from '@/views/custvolume/Index.vue'

// import Weight from '@/views/weight/index.vue'

// import Unitmanage from '@/views/unitmanage/Index.vue'
// import UnitmanageCreate from '@/views/unitmanage/Create.vue'

// import Unitrental from '@/views/unitrental/Index.vue'
// import UnitrentalCreate from '@/views/unitrental/Create.vue'

// import Employee from '@/views/employee/Index.vue'
// import EmployeeCreate from '@/views/employee/Create.vue'

// import Empsalarystats from '@/views/empsalarystats/Index.vue'
// import QueryBackups from '@/views/empsalarystats/queryBackups.vue'

// import Empwelfare from '@/views/empwelfare/Index.vue'
// import EmpwelfareCreate from '@/views/empwelfare/Create.vue'

// import Users from '@/views/users/Index.vue'
// import UsersCreate from '@/views/users/Create.vue'

// import Score from '@/views/file/score.vue'
// import Qualified from '@/views/file/qualified.vue'
// import CheckRate from '@/views/file/checkRate.vue'
// import SteelDefects from '@/views/file/steelDefects.vue'
// import standingBook from '@/views/file/standingBook.vue'
// import checkStandingBook from '@/views/file/checkStandingBook.vue'
// import inspectProject from '@/views/file/inspectProject.vue'
// import inspectUnit from '@/views/file/inspectUnit.vue'
// import inspectUnitInfo from '@/views/file/inspectUnitInfo.vue'
// import inspectCountscore from '@/views/file/inspectCountscore.vue'
// import inspectCountscoreInfo from '@/views/file/inspectCountscoreInfo.vue'

// import QuestionInfo from '@/views/file/questionInfo.vue'

// import DataRights from '@/views/dataRights/Index.vue'
// import CreateDataRights from '@/views/dataRights/Create.vue'

// import Auths from '@/views/auths/Index.vue'
// import AuthsCreate from '@/views/auths/Create.vue'

// import InspectReport from '@/views/inspectReport/Index.vue'

// import Roles from '@/views/roles/Index.vue'
// import RolesCreate from '@/views/roles/Create.vue'
// import RoleRights from '@/views/roleRights/Index.vue'
// import RoleRightsCreate from '@/views/roleRights/Create.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/',
    name: 'admin',
    component: Layout,
    //声明子组件
    // children: [{
    //     path: 'welcome',
    //     component: Welcome,
    children: [
      {
        path: '/workplace',
        component: Workplace,
        alias: '/',
        meta: {
          name1: '仪表盘',
          name2: '工作台',
          //需要登录的权限
          requireAuth: true
        }
      }

      // {
      //   path: '/detection',
      //   component: Detection,
      //   alias: '/detection',
      //   meta: {
      //     name1: '仪表盘',
      //     name2: '检测看板',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/inspect',
      //   component: Inspect,
      //   alias: '/inspect',
      //   meta: {
      //     name1: '仪表盘',
      //     name2: '检查看板',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/workload',
      //   component: Workload,
      //   alias: '/workload',
      //   meta: {
      //     // requiresAuth: true,
      //     name1: '业务',
      //     name2: '工作量统计',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/custvolume',
      //   component: Custvolume,
      //   meta: {
      //     name1: '业务',
      //     name2: '客户报检量统计',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/weight',
      //   component: Weight,
      //   meta: {
      //     name1: '业务',
      //     name2: '权重配置',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/unitmanage',
      //   component: Unitmanage,
      //   meta: {
      //     name1: '业务',
      //     name2: '单位管理',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/unitmanage/create',
      //   component: UnitmanageCreate,
      //   meta: {
      //     name1: '单位管理',
      //     name2: '单位创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/unitrental',
      //   component: Unitrental,
      //   meta: {
      //     name1: '业务',
      //     name2: '租赁单位管理',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/unitrental/create',
      //   component: UnitrentalCreate,
      //   meta: {
      //     name1: '租赁单位管理',
      //     name2: '单位创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/employee',
      //   component: Employee,
      //   meta: {
      //     name1: '工资管理',
      //     name2: '员工基本工资配置',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/employee/create',
      //   component: EmployeeCreate,
      //   meta: {
      //     name1: '基本工资管理',
      //     name2: '基本工资创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/empsalarystats',
      //   component: Empsalarystats,
      //   meta: {
      //     name1: '工资管理',
      //     name2: '工资统计',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/empsalarystats/queryBackups',
      //   component: QueryBackups,
      //   meta: {
      //     name1: '工资管理',
      //     name2: '已确认工资',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/empwelfare',
      //   component: Empwelfare,
      //   meta: {
      //     name1: '工资管理',
      //     name2: '员工福利管理',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/empwelfare/create',
      //   component: EmpwelfareCreate,
      //   meta: {
      //     name1: '员工福利管理',
      //     name2: '福利信息创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/score',
      //   component: Score,
      //   meta: {
      //     name1: '检查数据',
      //     name2: '检查得分统计',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/qualified',
      //   component: Qualified,
      //   meta: {
      //     name1: '检测数据',
      //     name2: '检测合格率',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/checkRate',
      //   component: CheckRate,
      //   meta: {
      //     name1: '检测数据',
      //     name2: '整改项比率',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/steelDefects',
      //   component: SteelDefects,
      //   meta: {
      //     name1: '检测数据',
      //     name2: '钢结构缺陷',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/questionInfo',
      //   component: QuestionInfo,
      //   meta: {
      //     name1: '检查数据',
      //     name2: '设备扣分详情',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/standingBook',
      //   component: standingBook,
      //   meta: {
      //     name1: '检测数据',
      //     name2: '检测业务台账',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/checkStandingBook',
      //   component: checkStandingBook,
      //   meta: {
      //     name1: '检查数据',
      //     name2: '检查业务台账',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/inspectProject',
      //   component: inspectProject,
      //   meta: {
      //     name1: '检查数据',
      //     name2: '各项目检查情况',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/inspectUnit',
      //   component: inspectUnit,
      //   meta: {
      //     name1: '检查数据',
      //     name2: '各责任单位检查情况',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/inspectUnitInfo',
      //   component: inspectUnitInfo,
      //   meta: {
      //     name1: '各责任单位检查情况',
      //     name2: '安装单位工程明细',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/inspectCountscore',
      //   component: inspectCountscore,
      //   meta: {
      //     name1: '检查数据',
      //     name2: '厂家检查情况',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/file/inspectCountscoreInfo',
      //   component: inspectCountscoreInfo,
      //   meta: {
      //     name1: '厂家检查情况',
      //     name2: '生产厂家工程明细',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/users',
      //   component: Users,
      //   meta: {
      //     name1: '用户',
      //     name2: '用户管理',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/users/create',
      //   component: UsersCreate,
      //   meta: {
      //     name1: '用户管理',
      //     name2: '用户创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/datarights',
      //   component: DataRights,
      //   meta: {
      //     name1: '用户',
      //     name2: '数据权限',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/datarights/create',
      //   component: CreateDataRights,
      //   meta: {
      //     name1: '数据权限',
      //     name2: '创建数据权限',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/roles',
      //   component: Roles,
      //   meta: {
      //     name1: '角色',
      //     name2: '角色列表',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/rolerights',
      //   component: RoleRights,
      //   meta: {
      //     name1: '角色',
      //     name2: '角色权限',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/rolerights/create',
      //   component: RoleRightsCreate,
      //   meta: {
      //     name1: '角色权限',
      //     name2: '角色权限创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/roles/create',
      //   component: RolesCreate,
      //   meta: {
      //     name1: '角色',
      //     name2: '角色创建',
      //     requireAuth: true
      //   }
      // },

      // {
      //   path: '/auths',
      //   component: Auths,
      //   meta: {
      //     name1: '权限',
      //     name2: '权限列表',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/auths/create',
      //   component: AuthsCreate,
      //   meta: {
      //     name1: '权限',
      //     name2: '权限创建',
      //     requireAuth: true
      //   }
      // },
      // {
      //   path: '/inspectReport',
      //   component: InspectReport,
      //   meta: {
      //     name1: '报告管理',
      //     name2: '检查报告',
      //     requireAuth: true
      //   }
      // }
    ]
  },
  {
    path: '/login',
    component: Login
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import( /* webpackChunkName: "about" */ '../views/Info.vue')
  // }
]
// const workRoute = {
//   path: '/workload',
//   component: Workload,
//   alias: '/workload',
//   meta: {
//     // requiresAuth: true,
//     name1: '业务',
//     name2: '工作量统计'
//   }
// }
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
import store from '@/store'
// 路由守卫
const whiteList = ['/login', '/404', 'workplace']
router.beforeEach((to, from, next) => {
  NProgress.start() // 开启进度条

  // 判断外来入口是否携带token，并且是否免登录,1:免登录
  if (to.query.token && !to.query.freeLogin) {
    store.dispatch('auths/DELETE_MENU')
    store.commit('login/STORE_TOKEN', to.query.token)
    if (store.state.auths.menus.length <= 0) {
      console.log(store.state.auths.menus, '重新获取权限菜单')
      store.dispatch('auths/FETCH_MENUS')
    }
    next()
    return
  }
  // 是否在白名单内
  if (whiteList.indexOf(to.path) != -1) {
    next()
  } else {
    // if (to.meta.requireAuth) {
    let token = store.state.login.token
    if (token && Number(to.query.freeLogin) != 1) {
      if (!store.state.auths.menus || store.state.auths.menus.length <= 0) {
        console.log(store.state.auths.menus, '重新获取权限菜单')
        store.dispatch('auths/FETCH_MENUS')
      }
      next()
      // 免登录
    } else if (Number(to.query.freeLogin) === 1) {
      // store.dispatch('login/DELETE_USERINFO')
      // store.dispatch('auths/DELETE_MENU')
      console.log('免登录')
      let loginForm = {}
      let params = to.query.dashboardUserName
      let params2 = to.query.dashboardUserPassword
      loginForm.loginName = params
      loginForm.password = params2
      handleLogin(loginForm, () => {
        if (!store.state.auths.menus || store.state.auths.menus.length <= 0) {
          console.log(store.state.auths.menus, '重新获取权限菜单')
          store.dispatch('auths/FETCH_MENUS')
        }
        //免登录不可修改密码
        sessionStorage.setItem('freeLogin', 1)
        next({ path: '/' })
        return
      })
    } else {
      next({
        path: '/login'
      })
    }
  }
})
router.afterEach(() => {
  NProgress.done()
})
// router.beforeEach((to, from, next) => {
//   if (['/login'].indexOf(to.path) != -1) {
//     next()
//   } else {
//     // 判断有无登录的权限
//     if (to.meta.requireAuth) {
//       let token = store.state.login.token
//       // console.log(token)
//       if (token) {
//         next()
//       } else {
//         next({
//           path: '/login'
//         })
//       }
//     } else {
//       next()
//     }
//   }
// })
// 解决跳转
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router

//登录逻辑
async function handleLogin(formData, cb) {
  try {
    let ret = await login({
      loginName: formData.loginName,
      password: SHA256(formData.password)
    })
    if (ret) cb && cb()
  } catch {
    cb && cb()
  }
}
