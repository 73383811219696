import router from '@/router/index'
import { common } from '@/api/interface'
import { SHA256 } from '@/utils/util'
export default {
  namespaced: true,
  state: {
    loginName: localStorage.getItem('loginName') || '',
    password: localStorage.getItem('password') || '',
    roleName: localStorage.getItem('roleName') || '',
    token: localStorage.getItem('token') || '',
    expireTime: localStorage.getItem('expireTime') || ''
  },
  getters: {},
  mutations: {
    DELETE_USERINFO(state) {
      state.loginName = ''
      state.roleName = ''
      state.token = ''
      localStorage.clear()
    },
    STORE_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    STORE_LOGINNAME(state, loginName) {
      state.loginName = loginName
      localStorage.setItem('loginName', loginName)
    },
    STORE_PASSWORD(state, password) {
      state.password = password
      localStorage.setItem('password', password)
    },
    STORE_TOKENEXPIRETIME(state, expireTime) {
      state.expireTime = expireTime
      localStorage.setItem('expireTime', expireTime)
    }
  },
  actions: {
    async login({ commit }, { data }) {
      const res = await common.post('/user/genSalt', '', '')
      console.log(res)
      if (res.code === 200) {
        let userInfo = {}
        userInfo.loginName = data.loginName
        userInfo.timeStamp = res.data.rkey
        userInfo.password = SHA256(data.password + res.data.rvalue)
        const ret = await common.post('/user/login', '', userInfo)
        if (ret.code === 200) {
          commit('STORE_LOGINNAME', data.loginName)
          commit('STORE_PASSWORD', data.password)
          commit('STORE_TOKEN', ret.data.token)
          commit('STORE_TOKENEXPIRETIME', ret.data.expireTime)
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    async getResponse({ dispatch }, { func, url, params, data, holder }) {
      if (!url) return
      if (!params) params = {}
      if (!holder) {
        holder = []
      } else {
        // holder = JSON.stringify(holder)
      }
      if (!func) func = common.post
      if (func === 'get') func = common.get
      if (func === 'jsonPost') func = common.jsonPost
      let iterationTimes = 0 // 302循环次数
      return new Promise((resolve, reject) => {
        dispatch('responseHandler', {
          func,
          url,
          params,
          data,
          holder,
          iterationTimes
        })
          .then((res) => {
            resolve(res)
          })
          .catch((res) => {
            reject(res)
          })
      })
    },
    async responseHandler(
      { dispatch, state },
      { func, url, params, data, holder, iterationTimes }
    ) {
      return new Promise((resolve, reject) => {
        func(url, params, data)
          .then(async (res) => {
            if (res.code >= 200 && res.code < 300) {
              if (res.code === 204) {
                resolve({})
              } else {
                resolve(res.data)
              }
            } else if (res.code >= 300 && res.code < 400) {
              if (iterationTimes < 1) {
                let loginData = {
                  loginName: state.loginName,
                  password: state.password
                }
                await dispatch('login', { data: loginData })
                iterationTimes++
                dispatch('responseHandler', {
                  func,
                  url,
                  params,
                  data,
                  holder,
                  iterationTimes
                }).then((res) => {
                  resolve(res)
                })
              } else {
                resolve(holder)
                let hosturl = location.host
                let path = location.href.split(hosturl, 2)[1]
                router.push('/login?redirectUrl=' + encodeURIComponent(path))
              }
            } else {
              resolve(holder)
            }
          })
          .catch((res) => {
            resolve(holder)
            reject(res)
            console.log('store/action/responseHandler获取数据失败', res)
          })
      })
    }
  }
}
