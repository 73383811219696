<template>
  <div class="top">
    <div class="left">
      <div class="btn" @click="$emit('changeMenu', menuWidth)">
        <i
          :class="menuWidth == '200px' ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
        ></i>
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ name1 }}</el-breadcrumb-item>
        <transition enter-active-class="animated fadeInRight">
          <el-breadcrumb-item :key="name2">{{ name2 }}</el-breadcrumb-item>
        </transition>
      </el-breadcrumb>
    </div>
    <div class="right">
      <el-dropdown @command="handleCommand" v-if="freeLogin != 1">
        <span class="el-dropdown-link">
          <i class="el-icon-user"></i>
          <span v-text="userName" style="padding-left: 4px"></span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-setting" command="1"
            >更新信息</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-edit-outline" command="2"
            >更改密码</el-dropdown-item
          >
          <!-- <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>

      <i class="el-icon-switch-button out" @click="logoutFn"></i>
      <!-- <i class="el-icon-full-screen"></i> -->
    </div>
    <Edit
      :state="editState"
      @close="editState = false"
      :user="editUser"
      @confirm="changeUser"
    />
    <EditPassword
      :state="editPasswordState"
      @close="editPasswordState = false"
      @confirm="changePassword"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { brequest } from '@/utils/brequest'
import Edit from './components/Edit.vue'
import EditPassword from './components/EditPassword'
// import router from '../../../router'
export default {
  components: {
    Edit,
    EditPassword
  },
  props: {
    menuWidth: {
      type: String,
      default: '200px'
    }
  },
  data() {
    return {
      freeLogin: 2, //1:免登录
      userInfo: {},
      name1: '',
      name2: '',
      userName: '',
      isCollapse: true,
      editState: false,
      editPasswordState: false,
      editUser: {}
      // menuWidthTemp: this.menuWidth
    }
  },
  created() {
    console.log(sessionStorage.getItem('freeLogin'), '0----------')
    this.freeLogin = sessionStorage.getItem('freeLogin')
    //1:免登录
  },
  methods: {
    ...mapMutations({
      logout: 'login/DELETE_USERINFO',
      saveLoginName: 'login/STORE_LOGINNAME',
      savePassword: 'login/STORE_PASSWORD',
      menus: 'auths/DELETE_MENU'
    }),
    logoutFn() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sessionStorage.removeItem('freeLogin')
          this.logout()
          this.menus()
          this.jump('/login')
        })
        .catch(() => {
          console.log('用户点击取消')
        })
    },
    handleCommand(command) {
      console.log(command)
      switch (command) {
        case '1':
          this.editUser = JSON.parse(JSON.stringify(this.userInfo))
          this.editState = true
          break
        case '2':
          this.editPasswordState = true
          break
        default:
      }
    },
    async queryUserInfo() {
      let userInfo = await brequest({
        url: '/user/queryUserInfo',
        method: 'post'
      })

      this.userInfo = userInfo
      this.userName = userInfo.userName
    },
    async changeUser(user) {
      this.$loading({
        text: '用户信息更新中'
      })
      let ret = await brequest({
        url: '/user/updateSelfInfo',
        data: user
      })
      this.$loading().close()
      if (ret > 0) {
        this.editState = false
        this.$message({
          message: '用户信息更新成功',
          type: 'success'
        })
        this.saveLoginName(user.loginName)
        this.queryUserInfo()
      }
    },
    async changePassword(passwd) {
      this.$loading({
        text: '用户密码更新中'
      })
      let ret = await brequest({
        url: '/user/updateSelfPassword',
        data: { passwd: passwd }
      })
      this.$loading().close()
      if (ret > 0) {
        this.editPasswordState = false
        this.$message({
          message: '用户密码更新成功',
          type: 'success'
        })
        this.savePassword(passwd)
        // this.queryUserInfo();
      }
    }
    // screenfullFn() {
    //   // 需要注意的是 如果判断!screenfull.enabled 显示的是不支持全屏的话 是因为谷歌的版本问题  判断改为 !screenfull.isEnabled  就可以了
    //   if (!screenfull.isEnabled) {
    //     // 如果不支持进入全屏，发出不支持提示
    //     this.$message({
    //       message: "您的浏览器版本过低不支持全屏显示！",
    //       type: "warning",
    //     });
    //     return false;
    //   }
    //   screenfull.toggle();
    // },
  },
  watch: {
    $route: {
      handler(newData) {
        this.name1 = newData.meta.name1
        this.name2 = newData.meta.name2
      },
      immediate: true
    }
  },
  async mounted() {
    this.queryUserInfo()
  }
}
</script>

<style lang="scss" scoped>
// top
.top {
  width: 100%;
  height: 50px;
  box-shadow: 2px 4px 6px rgb(0 21 41 / 10%);
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  // background: #263445;

  // left
  .left {
    width: 500px;
    display: flex;
    align-items: center;
    .btn {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      text-align: center;
      margin: 0 5px;
      &:hover {
        color: #409eff;
        background-color: rgba(0, 0, 0, 0.025);
        cursor: pointer;
      }
    }
  }

  // right
  .right {
    // width: 160px;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 2%;
    .el-icon-user {
      transform: scale(1.4);
    }
    .out {
      font-size: 20px;
      margin: 0 10px;
      color: #3d6fe6;
      background-color: #ececec;
      border-radius: 5px;
      padding: 4px;
      &:hover {
        cursor: pointer;
        color: #cc2242;
        transform: scale(1.2);
      }
    }
  }
}
</style>
