<template>
  <div class="welcome">
    <div class="center">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-card
            class="mgb20"
            style="height: 252px; box-shadow: 0 0 2px 1px #ccc"
          >
            <div class="user-info">
              <el-avatar :size="120" fit="contain" :src="imgurl" />
              <div class="user-info-cont">
                <!-- <div class="user-info-name">{{ userName }}</div> -->
                <div class="user-info-name">{{ timeFix }}，{{ userName }}</div>
              </div>
            </div>
            <div class="user-info-list">
              本次登录时间：
              <span>{{ nowtime }}</span>
            </div>
          </el-card>

          <el-card style="height: 334px; box-shadow: 0 0 2px 1px #ccc">
            <template #header>
              <div class="clearfix">
                <span>便捷导航</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text"
                  >添加</el-button
                > -->
              </div>
            </template>
            <div style="margin-top: 40px">
              <el-row>
                <div v-for="(item, index) in InfoData" :key="index">
                  <el-col :span="12">
                    <el-card shadow="hover">
                      <div
                        class="easypath"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-self: center;
                        "
                        @click="toNext(item.path)"
                      >
                        <i
                          :class="[item.icon]"
                          :style="{ color: item.color }"
                          class="icon-box iconfont"
                        />
                        <a
                          href="#"
                          style="
                            color: #409eff;
                            text-align: center;
                            text-decoration: none;
                          "
                        >
                          {{ item.title }}
                        </a>
                      </div></el-card
                    >
                  </el-col>
                </div>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="14">
          <el-card
            class="mgb20"
            style="height: 150px; box-shadow: 0 0 2px 1px #ccc"
          >
            <template #header>
              <div class="clearfix">
                <span>关键词</span>
              </div>
            </template>
            <el-row>
              <el-tag
                :key="tag"
                v-for="tag in dynamicTags"
                :disable-transitions="false"
                @close="handleClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              >
              </el-input>
              <!-- <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput"
                >+ New Tag</el-button
              > -->
            </el-row>
          </el-card>
          <div style="height: 440px; box-shadow: 0 0 2px 1px #ccc">
            <!-- <img
              style="width: 100%; height: 100%"
              src="@/assets/imgs/photo.jpg"
              alt=""
            /> -->
            <!-- <span class="demonstration">默认 Hover 指示器触发</span> -->
            <el-carousel height="440px" :interval="6000">
              <el-carousel-item v-for="item in imgList" :key="item.id">
                <img
                  style="width: 100%; height: 100%"
                  :src="item.imgurl"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { timeFix } from '@/utils/time'
import { brequest } from '@/utils/brequest'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      menus: (state) => state.auths.menus
    })
  },
  data() {
    return {
      timeFix: timeFix(),
      userInfo: {},
      userName: '',
      nowtime: '',
      dynamicTags: ['天宙检测', '检测仪表盘', '检查仪表盘', '数据统计'],
      imgList: [
        {
          imgurl: require('@/assets/imgs/dete.jpg'),
          id: 0
        },
        {
          imgurl: require('@/assets/imgs/photo.jpg'),
          id: 1
        },
        {
          imgurl: require('@/assets/imgs/photo2.jpg'),
          id: 2
        },
        {
          imgurl: require('@/assets/imgs/photo3.jpg'),
          id: 3
        },
        {
          imgurl: require('@/assets/imgs/photo4.jpg'),
          id: 4
        }
      ],
      inputVisible: false,
      inputValue: '',
      imgurl: require('@/assets/imgs/img.png'),
      InfoData: [
        {
          title: '检测业务仪表盘',
          icon: 'icon-gongzuotongji',
          color: '#f0992d',
          path: '/detection'
        },
        {
          title: '检查业务仪表盘',
          icon: 'icon-shebeiOEE',
          color: '#19be6b',
          path: '/inspect'
        }
      ]
    }
  },
  created() {
    this.getNowTime()
  },
  methods: {
    toNext(path) {
      // 判断是否有仪表盘菜单权限
      let isAuth = this.menus[0].subMenus.find((menu) => menu.link === path)
      console.log(this.menus)
      if (!isAuth) {
        this.jump('/404')
      } else {
        this.jump(path)
      }
    },
    getNowTime() {
      let dt = new Date()
      var y = dt.getFullYear()
      var mt = (dt.getMonth() + 1).toString().padStart(2, '0')
      var day = dt.getDate().toString().padStart(2, '0')
      var h = dt.getHours().toString().padStart(2, '0')
      var m = dt.getMinutes().toString().padStart(2, '0')
      this.nowtime = y + '-' + mt + '-' + day + ' ' + h + ':' + m
      console.log('此刻时间', this.nowtime)
    },
    async queryUserInfo() {
      let userInfo = await brequest({
        url: '/user/queryUserInfo',
        method: 'post'
      })

      this.userInfo = userInfo
      this.userName = userInfo.userName
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  },

  async mounted() {
    this.queryUserInfo()
  }
}
</script>

<style lang="scss" scoped>
// welcome
.welcome {
  height: 100%;
  // display: flex;
  width: 100%;
  padding: 2px 5px;

  // justify-content: center;
  // align-items: center;
  // background-color: #f5f5f5;
}
.el-row {
  width: 100%;
  margin-bottom: 10px;
}
.easypath:hover {
  cursor: pointer;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(100, 213, 114);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-info-cont {
  padding-left: 30px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 45px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  // background: #d3dce6;
  border: 1px solid #ddd;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.icon-box {
  font-size: 40px;
  width: 90%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #fff;
}
.el-carousel__item h3 {
  width: 100%;
  height: 100%;
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
</style>
