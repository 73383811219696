<template>
  <el-form :model="formData" :label-width="width" ref="form1">
    <!-- 表单选项 -->
    <el-form-item
      v-for="(item, index) in formConfig"
      :key="index"
      :prop="item.field"
      :label="item.label"
      :label-width="item.width"
      :rules="item.rules"
    >
      <!-- 输入框 -->
      <el-input
        type="text"
        :placeholder="item.placeholder"
        :prefix-icon="item.icon"
        :style="{ width: item.payload ? item.payload.width : '100%' }"
        :disabled="item.disabled"
        v-model="formData[item.field]"
        v-if="item.type === 'text'"
      >
        <i slot="suffix" class="dw" v-if="item.unit">{{
          item.unit
        }}</i></el-input
      >

      <!-- 密码框 -->
      <el-input
        type="password"
        :prefix-icon="item.icon"
        :placeholder="item.placeholder"
        :show-password="item.showPwd"
        v-model="formData[item.field]"
        v-if="item.type === 'password'"
      ></el-input>
      <!-- 密码框 -->

      <!-- 级联选择器 -->
      <el-cascader
        v-if="item.type === 'cascader'"
        :options="item.payload.options"
        v-model="formData[item.field]"
        @change="item.payload.change"
        :props="item.payload.props || { label: 'label' }"
        change-on-select
      >
      </el-cascader>
      <!-- 多选可搜索 -->
      <el-cascader
        v-if="item.type === 'cascaders'"
        v-model="formData[item.field]"
        :options="item.payload.options"
        :props="{ multiple: true }"
        filterable
      ></el-cascader>
      <!-- 级联选择器 -->
      <!-- 日期选择 -->
      <el-date-picker
        size="large"
        v-if="item.type === 'datePicker'"
        :value-format="item.format"
        :format="item.format"
        v-model="formData[item.field]"
        :type="item.pickerType"
        :editable="false"
        :placeholder="item.placeholder"
      >
      </el-date-picker>
      <!-- select选择器 -->
      <el-select
        :placeholder="item.placeholder"
        :filterable="item.filterable"
        :multiple="item.multiple"
        :reserve-keyword="true"
        v-if="item.type === 'select'"
        @change="selectChange(formData[item.field])"
        v-model="formData[item.field]"
      >
        <el-option
          v-for="item in item.payload"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- select选择器 -->
    </el-form-item>
    <!-- 表单选项 end -->

    <!-- 表单按钮 -->
    <div v-if="formBtns && formBtns.length" class="button-group">
      <el-button
        v-for="(item, index) in formBtns"
        :key="index"
        :type="item.type"
        :style="{ width: item.width || '70px' }"
        class="form-button"
        @click="submitFn(item.content, item.icon)"
      >
        <i v-if="item.icon" :class="item.icon"></i>
        <span v-else> {{ item.content }}</span>
      </el-button>
    </div>
    <!-- 表单按钮 end -->
  </el-form>
</template>

<script>
export default {
  // 获取属性
  props: {
    // 表格通用label-width
    // width: '0px'
    // 表格配置 一个对象代表后一个表格选项
    // formConfig: [
    //     {label: '标题',  width: '标题宽度', field: '标题字段', type: '输入框类型', rules: []}
    // ]
    // formBtns: [
    //     {content:'创建/更新/重置', type: 'primary/success/info/warning/danger', },
    //     {content:'创建/更新/重置', type: 'primary/success/info/warning/danger', },
    //     {content:'创建/更新/重置', type: 'primary/success/info/warning/danger', }
    // ]

    width: {
      type: String,
      default: '0x'
    },
    formConfig: {
      type: Array,
      required: true
    },

    formBtns: Array,
    row: Object // 留心：添加不传，但是修改得穿，其次不适合用vuex 因为不同表单 字符段不一样
  },
  created() {
    //   // 创建  undefined
    //   // 修改  对象
    // console.log('哈哈，我获取到你传递的默认数据啦！~', this.formData)
    //   if (this.row) {
    //     this.formData = this.row;
    //     this.row.state = this.row.state == 1 ? "正常" : "冻结";
    //   }
    //   // console.log(1, this.formData);
    //   // console.log(2, this.row);
  },
  watch: {
    // row(newData) {
    //   console.log("watch", newData);
    // },
    row: {
      handler(newData) {
        // console.log(newData)
        if (!newData) return

        // console.log("watch", newData);
        this.formData = newData
        console.log(this.formData, '===')
      },
      immediate: true
    }
  },
  // 模型数据
  data() {
    return {
      formData: {}
    }
  },
  methods: {
    submitFn(content, icon) {
      if (!content) {
        // 例如登录扫码登录就只有图标没有登录文字
        this.$emit('click', icon)
        return
      }

      if (content === '重置') {
        this.$refs.form1.resetFields()
      } else {
        // 语法：this.$refs.ref属性值.validate(isSuccess=> {})
        // 作用：表单提交也验证下
        this.$refs.form1.validate((isSuccess) => {
          if (isSuccess) {
            this.$emit('submit', this.formData)
          }
        })
      }
    },
    selectChange(val) {
      console.log(this.formData, '====')
      // this.$emit('submit', this.formData)
      // console.log(val)
      // console.log(this.$refs.form1)
      this.$emit('selectChange', val, this.formData)
    },
    handleSelect(item) {
      console.log(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  justify-content: center;
  .form-button {
    margin: 0 20px;
  }
}
.dw {
  font-style: normal;
  color: #000000;
  line-height: 45px;
}
::v-deep .el-input__suffix {
  // width: 50%;
}
</style>
