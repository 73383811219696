<template>
  <div class="login">
    <!-- 账号登录 -->
    <div class="logform animated bounceInDown">
      <h1>检测检查管理系统</h1>
      <tz-form
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
    </div>
    <!-- 账号登录 -->
  </div>
</template>

<script>
// import Saoma from "./components/Saoma.vue";
import { GetUrlValueByParamName, SHA256 } from '@/utils/util'
import { login } from '@/utils/brequest'

export default {
  data() {
    return {
      // 扫码登录
      //   saomaState: false,
      // 表单配置
      formConfig: [
        {
          placeholder: '请输入账号',
          icon: 'el-icon-s-custom',
          field: 'loginName',
          type: 'text',
          rules: [{ required: true, message: '账号必填', trigger: 'blur' }]
        },
        {
          placeholder: '请输入密码',
          icon: 'el-icon-lock',
          field: 'password',
          type: 'password',
          showPwd: true,
          rules: [{ required: true, message: '密码必填', trigger: 'blur' }]
        }
      ],
      formBtns: [
        {
          content: '登录',
          type: 'primary',
          width: '145px'
        },
        {
          content: '重置',
          type: '',
          width: '145px'
        }
      ]
    }
  },
  methods: {
    // btnClickFn(icon) {
    //   console.log(icon);
    //   if (icon === "el-icon-camera-solid") {
    //     this.saomaState = true;
    //   } else if (icon === "el-icon-mobile-phone") {
    //     this.jump("/login/sms");
    //   } else if (icon === "el-icon-s-claim") {
    //     this.jump("/login/token");
    //   }
    // },
    async submitFn(formData) {
      let ret = await login({
        loginName: formData.loginName,
        password: SHA256(formData.password)
      })
      if (ret) {
        let url = '/'
        let params = location.href.split('?')[1]
        if (params) {
          let str = GetUrlValueByParamName('redirectUrl', params)
          if (str) {
            url = decodeURIComponent(str)
          }
        }
        this.$router.push(url)
      } else {
        console.error('login failed')
        this.$message({
          message: '登录失败',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  //   background-color: #2d3a4b;
  background-image: url('../../assets/imgs/background.png');
  display: flex;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;

  .logform {
    width: 300px;
    h1 {
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
    }
    // ::v-deep .el-button {
    //   font-size: 15px;
    //   span {
    //     margin-left: -8px;
    //   }
    // }
    // ::v-deep .el-select {
    //   width: 100%;
    // }
  }
}
</style>
