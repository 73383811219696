import axios from 'axios'
import store from '@/store'
import router from '@/router/index'
import { Message } from 'element-ui'

const request = axios.create({
  // baseURL: 'http://dashboardapi.jsjxtzjc.com/'
  baseURL: '/api'
})

request.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token') || ''
    config.headers.Authorization = 'Bearer:' + token
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

request.interceptors.response.use(
  (response) => {
    // 未鉴权
    if (response.data.msg == '未鉴权') {
      Message.error('无权访问，跳转中...')
      store.commit('auths/DELETE_MENU', [])
      router.push('/login')
      return {}
    }
    return response.data
  },
  (err) => {
    return Promise.reject(err)
  }
)

// 导出request对象
export default request
