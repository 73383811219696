<template>
  <el-dialog
    title="更新信息"
    :visible.sync="state"
    width="30%"
    :before-close="handleClose"
  >
    <!-- 内容 -->
    <tz-form
      width="80px"
      :formConfig="formConfig"
      :formBtns="formBtns"
      :row="user"
      @submit="submitFn"
    ></tz-form>
    <!-- 内容 -->
  </el-dialog>
</template>

<script>
import { brequest } from '@/utils/brequest'
export default {
  props: {
    state: {
      type: Boolean,
      default: false
    },
    user: Object
  },

  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '账户',
          field: 'loginName',
          type: 'text',
          rules: [
            { required: true, message: '账户必填', trigger: 'blur' },
            { min: 3, max: 20, message: '长度为3到20', trigger: 'blur' },
            {
              validator: this.checkLoginName,
              trigger: 'blur'
            }
          ]
        },
        {
          label: '手机号',
          field: 'mobilePhone',
          type: 'text',
          rules: [
            { required: true, message: '手机号必填', trigger: 'blur' },
            {
              validator: this.checkMobilePhone,
              trigger: 'blur'
            }
          ]
        },
        {
          label: '用户姓名',
          field: 'userName',
          type: 'text',
          rules: [
            { required: true, message: '用户姓名必填', trigger: 'blur' },
            { min: 2, max: 20, message: '长度为2到20', trigger: 'blur' }
          ]
        }
        // },
        // {
        //   label: '密保问题',
        //   field: 'question',
        //   type: 'select',
        //   payload: [
        //     { label: '母亲的名字', value: '母亲的名字' },
        //     { label: '爷爷的名字', value: '爷爷的名字' },
        //     { label: '您其中一位老师的名字', value: '您其中一位老师的名字' }
        //   ],
        //   rules: [{ required: true, message: '密保问题必填', trigger: 'blur' }]
        // },
        // {
        //   label: '密保答案',
        //   field: 'answer',
        //   type: 'text',
        //   rules: [{ required: true, message: '密保问题必填', trigger: 'blur' }]
        // }
      ],

      formBtns: [
        {
          content: '更新',
          width: '185px',
          type: 'primary'
          // },
          // {
          //   content: '重置',
          //   type: ''
        }
      ]
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    submitFn(formData) {
      this.$emit('confirm', formData)
      console.log('更新数据处理', formData)
    },
    async checkLoginName(rule, value, callback) {
      let ret = await brequest({
        url: '/user/countUserByLoginName',
        data: { id: this.user.id, loginName: value }
      })
      if (ret > 0) {
        return callback('登录名有重复')
      }
      return callback()
    },
    async checkMobilePhone(rule, value, callback) {
      if (!/^1\d{10}$/.test(value)) return callback('手机号格式有误')
      let ret = await brequest({
        url: '/user/countUserByMobilePhone',
        data: { id: this.user.id, mobilePhone: value }
      })
      if (ret > 0) {
        return callback('手机号有重复')
      }
      return callback()
    }
  }
}
</script>

<style></style>
